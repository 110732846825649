<template>
  <!-- 挂号记录 -->
  <div class="registration-record">
    <!-- 切换 -->
    <Scroll
      class="region-scroll"
      ref="scroll"
      :probeType="3"
      @pullingUp="scrollBottom"
      :pullUpLoad="true"
    >
      <SwitchPeople />
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        @load="onLoad"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
      >
        <!-- 空状态 -->
        <div v-if="registrationList.list.length == 0">
          <van-empty description="无挂号记录" />
        </div>
        <!-- 挂号信息 -->
        <div
          v-else
          class="registration-list"
          v-for="item in registrationList.list"
          :key="item.id"
          @click="query(item)"
        >
          <div>
            <span>就诊人:</span>
            <span>{{cardList[0].name}}</span>
          </div>
          <div>
            <span>科室:</span>
            <span>{{item.departMent}}</span>
          </div>
          <div>
            <span>医生:</span>
            <span>{{item.doctor}}</span>
          </div>
          <div>
            <span>挂号状态:</span>
            <span :style="{color:item.orderFlag == 0 ? '#a6ec97':'#8d8fa1'}">{{item.orderFlag == 0 ? "成功" : "已取消"}}</span>
          </div>
          <div class="line">
            <span>就诊时间:</span>
            <span>{{item.diagnosisTime}}</span>
          </div>
          <!-- <van-button
            class="last-child"
            type="info"
            block
            round
            @click="evaluate"
          >评价</van-button> -->
        </div>
      </van-list>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { questionnaireQuery, feedback } from '@/network/service'
import { mapState } from 'vuex'
export default {
  name: 'registrationRecord',
  components: {
    Scroll,
    SwitchPeople
  },
  data () {
    return {
      //等待加载
      loading: false,
      //没有更多了
      finished: false,
      //请求失败，点击重新加载
      error: false,
      list: []
    }
  },
  computed: {
    ...mapState(['nameState', 'cardList', 'headPortrait', 'valve', 'registrationList', 'pageSet', 'seq'])
  },
  mounted () {
    //重置信息
    this.registrationList.page = 1
    this.registrationList.list = []
    this.$store.state.valve = true
    //挂号信息查询请求
    this.registerPost()
  },
  methods: {
    //评价
    async evaluate () {
      let moduleId = await questionnaireQuery()
      let result = moduleId.data.filter(el => el.id == "1")[0].templetId
      let res = await feedback({
        id: result
      })
      this.list = JSON.parse(res.data.content)
      // this.$router.push({ path: '/questionnaire', query: { id: result } })
    },
    //点击重新加载
    onLoad () {
      this.registerPost()
    },
    //挂号信息查询请求
    registerPost () {
      this.$store.commit('INFORMATION_SERVICE', {
        type: '挂号',
        name: 'registrationList',
        _this: this
      })
    },
    //单个挂号查询
    query (item) {
      this.$router.push({
        path: '/registration-cancellation', query: {
          departMent: item.departMent,
          diagnosisTime: item.diagnosisTime,
          doctor: item.doctor,
          shift: item.shift,
          bid: item.bid,
          registerSeq: item.registerSeq,
          flag: item.orderFlag
        }
      })
    },
    //下拉加载
    scrollBottom () {
      if (this.valve && this.registrationList.list.length >= this.pageSet) this.registerPost()
    }
  },
}
</script>

<style lang="less" scoped>
.registration-record {
  background-color: #f4f4f4;
  height: 100vh;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .registration-list {
      overflow: hidden;
      background-color: rgba(254, 254, 254);
      line-height: 2rem;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
      .arrow {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
      }
      div {
        display: flex;
        span:first-child {
          padding-left: 1rem;
          flex: 0.3;
          color: #8d8fa1;
        }
        span {
          flex: 0.6;
        }
      }
      .line {
        border-bottom: 1px solid @lineColor;
      }
      .van-button {
        float: right;
        margin-top: 0.5rem;
        margin-right: 1rem;
        width: 4rem;
        height: 2rem;
        background: @darkColor;
        border: 0.0625rem solid @darkColor;
      }
      .van-button__text {
        padding-left: 0 !important;
        color: #f4f4f4 !important;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}
</style>